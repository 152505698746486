import React from "react";
import { Link } from "react-router-dom";
import {
  FaFacebook,
  FaTwitter,
  FaLinkedin,
  FaInstagram,
  FaMapMarkerAlt,
  FaPhone,
  FaEnvelope,
} from "react-icons/fa";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t, i18n } = useTranslation();
  const handleLinkClick = () => {
    window.scrollTo(0, 0);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  return (
    <footer className="bg-gradient-to-r from-brand-green-800 to-brand-green-900 text-white dark:bg-gradient-to-r dark:from-gray-800 dark:to-gray-900">
      <div className="container mx-auto px-4 py-16">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12">
          <div className="space-y-4">
            <h3 className="text-2xl font-bold text-brand-yellow-500 dark:text-brand-yellow-400">
              {t("SOCOCOM SARL")}
            </h3>
            <p className="text-gray-300 dark:text-gray-400">
              {t("Your trusted partner in global logistics solutions.")}
            </p>
            <div className="flex space-x-4">
              <a
                href="#"
                className="text-gray-300 hover:text-brand-yellow-500 transition duration-300 dark:text-gray-400 dark:hover:text-brand-yellow-400"
              >
                <FaFacebook size={24} />
              </a>
              <a
                href="#"
                className="text-gray-300 hover:text-brand-yellow-500 transition duration-300 dark:text-gray-400 dark:hover:text-brand-yellow-400"
              >
                <FaTwitter size={24} />
              </a>
              <a
                href="#"
                className="text-gray-300 hover:text-brand-yellow-500 transition duration-300 dark:text-gray-400 dark:hover:text-brand-yellow-400"
              >
                <FaLinkedin size={24} />
              </a>
              <a
                href="#"
                className="text-gray-300 hover:text-brand-yellow-500 transition duration-300 dark:text-gray-400 dark:hover:text-brand-yellow-400"
              >
                <FaInstagram size={24} />
              </a>
            </div>
          </div>
          <div>
            <h3 className="text-xl font-bold mb-4 text-brand-yellow-500 dark:text-brand-yellow-400">
              {t("Quick Links")}
            </h3>
            <ul className="space-y-2">
              {[t("Home"), t("Services"), t("About Us"), t("Contact")].map(
                (item) => (
                  <li key={item}>
                    <Link
                      to={
                        item === t("About Us")
                          ? "/about"
                          : `/${item.toLowerCase()}`
                      }
                      className="text-gray-300 hover:text-brand-yellow-500 transition duration-300 flex items-center dark:text-gray-400 dark:hover:text-brand-yellow-400"
                      onClick={handleLinkClick}
                    >
                      <span className="mr-2">&#8227;</span> {item}
                    </Link>
                  </li>
                )
              )}
            </ul>
          </div>
          <div>
            <h3 className="text-xl font-bold mb-4 text-brand-yellow-500 dark:text-brand-yellow-400">
              {t("Our Services")}
            </h3>
            <ul className="space-y-2">
              {[
                t("Sea Freight"),
                t("Air Freight"),
                t("Land Transport"),
                t("Warehousing"),
                t("Import/Export"),
                t("Project Cargo"),
                t("Business Consultation"),
                t("Hospitality"),
              ].map((service) => (
                <li key={service}>
                  <Link
                    to="/services"
                    className="text-gray-300 hover:text-brand-yellow-500 transition duration-300 flex items-center dark:text-gray-400 dark:hover:text-brand-yellow-400"
                    onClick={handleLinkClick}
                  >
                    <span className="mr-2">&#8227;</span> {service}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <h3 className="text-xl font-bold mb-4 text-brand-yellow-500 dark:text-brand-yellow-400">
              {t("Contact Us")}
            </h3>
            <ul className="space-y-2">
              <li className="flex items-start text-gray-300 dark:text-gray-400">
                <FaMapMarkerAlt className="mr-2 mt-1 text-brand-yellow-500 dark:text-brand-yellow-400" />
                <span>
                  4746, avenue laurent désiré kabila, immeuble la vision,
                  commune de manika, République Démocratique du Congo
                </span>
              </li>
              <li className="flex items-start text-gray-300 dark:text-gray-400">
                <FaPhone className="mr-2 mt-1 text-brand-yellow-500 dark:text-brand-yellow-400" />
                <span>
                  +243 978 110 472
                  <br />
                  +243 990 203 095
                </span>
              </li>
              <li className="flex items-start text-gray-300 dark:text-gray-400">
                <FaEnvelope className="mr-2 mt-1 text-brand-yellow-500 dark:text-brand-yellow-400" />
                <span>info@sococomsarl.com</span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="bg-brand-green-900 py-4 dark:bg-gray-900">
        <div className="container mx-auto px-4 flex flex-wrap justify-between items-center text-sm">
          <div className="w-full md:w-auto text-center md:text-left mb-2 md:mb-0 text-gray-300 dark:text-gray-400">
            &copy; {new Date().getFullYear()} SOCOCOM SARL.{" "}
            {t("All rights reserved.")}
          </div>
          <div className="w-full md:w-auto text-center md:text-right text-gray-300 dark:text-gray-400">
            {t("Developed and Maintained by")}{" "}
            <a
              href="https://wa.me/254729433643"
              target="_blank"
              rel="noopener noreferrer"
              className="text-brand-yellow-500 hover:text-brand-yellow-400 transition duration-300 dark:text-brand-yellow-400 dark:hover:text-brand-yellow-300"
            >
              PaulBony Systems
            </a>
          </div>
        </div>
      </div>

      {/* Language switcher */}
      <div className="bg-brand-green-900 py-2 dark:bg-gray-900">
        <div className="container mx-auto px-4 flex justify-center">
          <button
            onClick={() => changeLanguage("en")}
            className="mx-2 text-gray-300 hover:text-brand-yellow-500 dark:text-gray-400 dark:hover:text-brand-yellow-400"
          >
            English
          </button>
          <button
            onClick={() => changeLanguage("fr")}
            className="mx-2 text-gray-300 hover:text-brand-yellow-500 dark:text-gray-400 dark:hover:text-brand-yellow-400"
          >
            Français
          </button>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
